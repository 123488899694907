import logo from './logo.svg';
import './App.css';
import Router from './routers/Router';
import './theme-rtl.min.css';
import './theme.min.css';
import './theme.min.css.map';
function App() {
  return (
    <>
    <Router/>
    </>
  );
}

export default App;
